import mock from '../mock'
/*eslint-disable */
const previousDay = new Date(new Date().getTime() - 24 * 60 * 60 * 1000)
const dayBeforePreviousDay = new Date(new Date().getTime() - 24 * 60 * 60 * 1000 * 2)
import axiosInstanceQuqo from "../../../src/services/handlingPetitions";

const data = {
  profileUser: {
    id: 11,
    avatar: require('@src/assets/images/portrait/small/avatar-s-11.jpg').default,
    fullName: 'John Doe',
    role: 'admin',
    about:
      'Dessert chocolate cake lemon drops jujubes. Biscuit cupcake ice cream bear claw brownie brownie marshmallow.',
    status: 'online',
    settings: {
      isTwoStepAuthVerificationEnabled: true,
      isNotificationsOn: false
    }
  },
  contacts: [],
  chats: [],
  listProductsRecommended: [],
  currentUserIndex: 0,
  currentKeyIframe: 0,
}
/*eslint-enable */
// ------------------------------------------------
// GET: Return Chats Contacts and Contacts
// ------------------------------------------------
mock.onGet('/apps/chat/chats-and-contacts').reply(async () => {
  const chatsContacts = data.chats.map(chat => {
    const contact = data.contacts.find(c => c.id === chat.userId)
    contact.chat = { id: chat.id, unseenMsgs: chat.unseenMsgs, lastMessage: chat.chat[chat.chat.length - 1] }
    return contact
  })

  const response = await axiosInstanceQuqo.get(
    `${process.env.REACT_APP_API_URL_V2}/chat/listChats`
  );

  const getItemsStoreContacts = (user) => {
    if (data.contacts.length === 0) return user.items || [];

    //TODO: const currentContact = data.contacts.filter((usr) => usr.id === user.id);

    // if (currentContact.length > 0) {
    //   return currentContact[0].items
    // }

    console.log("user.items: ", user.items)

    return user.items || [];
  }

  let chatsContacts2 = [];
  // if (data.contacts.length === 0) {
  response.data.data.chats.forEach((user, ind) => {
    chatsContacts2.push({
      about: user.key,
      avatar: require('@src/assets/images/avatars/avatar-blank.png').default,
      address: user.address,
      orders: user.orders,
      created_at: user.CreatedAt,
      name_address: user.name_address,
      type_purchase: user.type_purchase,
      any_delivery_order: user.any_delivery_order,
      fullName: user.name,
      items: getItemsStoreContacts(user),
      id: user.id,
      take_agent: user.take_agent,
      role: "",
      status: user.take_agent ? "online" : "busy",
      index: ind,
    })
  })
  // } else {
  //   chatsContacts2 = data.contacts;
  // }

  data.contacts = chatsContacts2;

  const profileUserData = {
    id: data.profileUser.id,
    avatar: data.profileUser.avatar,
    fullName: data.profileUser.fullName,
    status: data.profileUser.status
  }
  return [200, { chatsContacts, contacts: data.contacts, profileUser: profileUserData }]
})

// ------------------------------------------------
// GET: Return User Profile
// ------------------------------------------------
mock.onGet('/apps/chat/users/profile-user').reply(() => [200, data.profileUser])

// ------------------------------------------------
// GET: Return Single Chat
// ------------------------------------------------
mock.onGet('/apps/chat/get-chat').reply(config => {
  // Get event id from URL
  let userId = config.params.id

  if (userId != null) {
    //  Convert Id to number
    userId = Number(userId)

    const chat = data.chats.find(c => c.id === userId)
    if (chat) chat.unseenMsgs = 0
    const contact = data.contacts.find(c => c.id === userId)
    if (contact.chat) contact.chat.unseenMsgs = 0
    return [200, { chat, contact }]
  }

  return [200, { chat: {}, contact: {} }]
})

// ------------------------------------------------
// GET: Return Single Chat
// ------------------------------------------------
mock.onGet('/apps/chat/changeStatusSidebar').reply(config => {
  // Get event id from URL
  let statusSidebarChat = config.params.statusSidebarChat
  let isSidebarRecommended = config.params.isSidebarRecommended
  let isSidebarPromotions = config.params.isSidebarPromotions
  data.listProductsRecommended = [];

  return [200, { statusSidebarChat, isSidebarRecommended, isSidebarPromotions }]
})

// ------------------------------------------------
// GET: Return Single Chat
// ------------------------------------------------
mock.onGet('/apps/chat/listProductsSideBar').reply(config => {
  // Get event id from URL
  let listProductsSideBar = config.params

  return [200, { listProductsSideBar }]
})

// ------------------------------------------------
// GET: Return Single Chat
// ------------------------------------------------
mock.onGet('/apps/chat/addProductsRecommended').reply(config => {
  // Get event id from URL
  if (config.params.type) {
    data.listProductsRecommended.push(
      config.params.product,
    )
  } else {
    const filterProductInList = data.listProductsRecommended.filter(
      (product) => product.ean === config.params.product.ean,
    );

    const indOf = data.listProductsRecommended.indexOf(filterProductInList[0])

    if (data.listProductsRecommended[indOf]) {
      data.listProductsRecommended.splice(indOf, 1);
    }
  }

  return [200, { listProductsRecommended: data.listProductsRecommended }]
})

// ------------------------------------------------
// GET: Return Single Chat
// ------------------------------------------------
mock.onPost('/apps/chat/addToCartInChat').reply(async (config) => {
  // Get event id from URL

  const params = JSON.parse(config.data).params;

  const itemsUser = [...data.contacts[params.userIndex].items];

  const filterProductInCart = itemsUser.filter((product) => product.product_retailer_id === params.product.ean);

  if (filterProductInCart.length > 0) {
    const indexOf = itemsUser.indexOf(filterProductInCart[0]);
    itemsUser[indexOf].quantity = (itemsUser[indexOf].quantity + (params.type ? 1 : -1));

    if (itemsUser[indexOf].quantity === 0) {
      itemsUser.splice(indexOf, 1);
    }
  } else {
    itemsUser.push({
      "currency": "COP",
      "item_price": params.product.current_price,
      "product_retailer_id": params.product.ean,
      "quantity": 1,
      "product": {
        "name": params.product.name,
        "image": params.product.images[0].image_url,
      }
    })
  }

  data.contacts[params.userIndex].items = itemsUser;

  data.contacts = [...data.contacts];


  return [200, { contacts: data.contacts }]
})

// ------------------------------------------------
// DELETE: Return Single Chat
// ------------------------------------------------
mock.onPost('/apps/chat/removeProductInChat').reply(async (config) => {
  // DELETE event id from URL

  const params = JSON.parse(config.data).params;

  const itemsUser = [...data.contacts[params.userIndex].items];

  const filterProductInCart = itemsUser.filter((product) => product.product_retailer_id === params.product.product_retailer_id);

  if (filterProductInCart.length > 0) {
    const indexOf = itemsUser.indexOf(filterProductInCart[0]);
    itemsUser.splice(indexOf, 1);
  }

  data.contacts[params.userIndex].items = itemsUser;

  data.contacts = [...data.contacts];


  return [200, { contacts: data.contacts }]
})

// ------------------------------------------------
// POST: Add new chat message
// ------------------------------------------------
mock.onPost('/apps/chat/send-msg').reply(config => {
  // Get event from post data
  const { obj } = JSON.parse(config.data)

  let activeChat = data.chats.find(chat => chat.userId === obj.contact.id)

  const newMessageData = {
    message: obj.message,
    time: new Date(),
    senderId: 11
  }
  // If there's new chat for user create one
  let isNewChat = false
  if (activeChat === undefined) {
    isNewChat = true

    // const lastId = data.chats[length - 1].id

    data.chats.push({
      id: obj.contact.id,
      userId: obj.contact.id,
      unseenMsgs: 0,
      chat: [newMessageData]
    })
    activeChat = data.chats[data.chats.length - 1]
  } else {
    activeChat.chat.push(newMessageData)
  }

  const response = { newMessageData, id: obj.contact.id }
  if (isNewChat) response.chat = activeChat

  return [201, { response }]
})

// ------------------------------------------------
// POST: Add new chat message
// ------------------------------------------------
mock.onPost('/apps/chat/changeCurrentKeyIframe').reply(() => {

  data.currentKeyIframe = data.currentKeyIframe + 1;

  return [201, { data: data.currentKeyIframe }]
})
