import axios from 'axios'

// QUQO INSTANCE

const axiosInstanceQuqo = axios.create({
  baseURL: '',
  withCredentials: false
})

function returnToken() {
  return new Promise((resolve) => {
    const sesionStorage = localStorage.getItem('userData')

    if (sesionStorage && JSON.parse(sesionStorage)) {
      const userToken = JSON.parse(sesionStorage).accessToken
      resolve(userToken)
    }
  })
}

export async function requestHandler(request) {
  request.headers.Authorization = await returnToken()
  const currentStoreID = localStorage.getItem("currentStoreID");

  console.log("currentStoreID: ", currentStoreID)

  request.headers.post['Content-Type'] = 'application/json'
  if (currentStoreID != null) {
    request.headers.get['Store-Id'] = currentStoreID
    request.headers.post['Store-Id'] = currentStoreID
  }
  return request
}

// REQUEST
axiosInstanceQuqo.interceptors.request.use(
  request => requestHandler(request)
)

const responseHandler = (response) => {
  return response
}


const errorHandler = (error) => {
  return error
}

// RESPONSE
axiosInstanceQuqo.interceptors.response.use(
  response => responseHandler(response),
  error => errorHandler(error)
)

// FINISH RESPONSE HANDLER

export default axiosInstanceQuqo
