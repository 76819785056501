import mock from "../mock";
/* eslint-disable */
// ** Utils
import { paginateArray, sortCompare } from "../utils";
import axiosInstanceQuqo from "../../../src/services/handlingPetitions";

const nextDay = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
const nextWeek = new Date(nextDay.getTime() + 7 * 24 * 60 * 60 * 1000);

const data = {
  products: [],
  userWishlist: [],
  shoppingCart: {},
  userCart: [],
  alert_msg: "",
  pointsUsed: 0,
  couponID: 0,
};
/* eslint-enable */

// ------------------------------------------------
// GET: Return products
// ------------------------------------------------

mock.onGet("/apps/ecommerce/products").reply((config) => {
  // eslint-disable-next-line object-curly-newline
  const { q = "", sortBy = "featured", perPage = 9, page = 1 } = config.params;

  const queryLowered = q.toLowerCase();

  const filteredData = data.products.filter((product) =>
    product.name.toLowerCase().includes(queryLowered)
  );

  let sortDesc = false;
  const sortByKey = (() => {
    if (sortBy === "price-desc") {
      sortDesc = true;
      return "price";
    }
    if (sortBy === "price-asc") {
      return "price";
    }
    sortDesc = true;
    return "id";
  })();

  const sortedData = filteredData.sort(sortCompare(sortByKey));
  if (sortDesc) sortedData.reverse();

  const paginatedData = JSON.parse(
    JSON.stringify(paginateArray(sortedData, perPage, page))
  );

  paginatedData.forEach((product) => {
    /* eslint-disable no-param-reassign */
    product.isInWishlist =
      data.userWishlist.findIndex((p) => p.productId === product.id) > -1;
    product.isInCart =
      data.userCart.findIndex((p) => p.productId === product.id) > -1;
    /* eslint-enable */
  });

  return [
    200,
    {
      products: paginatedData,
      total: filteredData.length,
      userWishlist: data.userWishlist,
      userCart: data.userCart,
    },
  ];
});

// ------------------------------------------------
// GET: Return Single Product
// ------------------------------------------------
mock.onGet(/\/apps\/ecommerce\/products\/\d+/).reply((config) => {
  // Get product id from URL
  let productId = config.url.substring(config.url.lastIndexOf("/") + 1);

  // Convert Id to number
  productId = Number(productId);

  const productIndex = data.products.findIndex((p) => p.id === productId);
  const product = data.products[productIndex];

  if (product) {
    // Add data of wishlist and cart
    product.isInWishlist =
      data.userWishlist.findIndex((p) => p.productId === product.id) > -1;
    product.isInCart =
      data.userCart.findIndex((p) => p.productId === product.id) > -1;

    // * Add Dummy data for details page
    product.colorOptions = ["primary", "success", "warning", "danger", "info"];

    return [200, { product }];
  }
  return [404];
});

// ------------------------------------------------
// GET: Return Wishlist Products
// ------------------------------------------------
mock.onGet("/apps/ecommerce/wishlist").reply(() => {
  const products = data.userWishlist.map((wishlistProduct) => {
    const product = data.products.find(
      (p) => p.id === wishlistProduct.productId
    );
    product.isInCart =
      data.userCart.findIndex(
        (p) => p.productId === wishlistProduct.productId
      ) > -1;
    return product;
  });

  return [200, { products }];
});

// ------------------------------------------------
// GET: Return Cart Products
// ------------------------------------------------
mock.onGet("/apps/ecommerce/cart").reply(() => {
  if (Object.keys(data.shoppingCart).length === 0) {
    const productsLocalStorage = localStorage.getItem("shopping_cart");

    if (productsLocalStorage !== null) {
      data.shoppingCart = JSON.parse(productsLocalStorage);
    }
  }

  return [200, { shoppingCart: data.shoppingCart }];
});

// ------------------------------------------------
// POST: Return Cart Products
// ------------------------------------------------
mock.onPost("/apps/ecommerce/successCreateOrder").reply((config) => {
  // Get product from post data
  const params = JSON.parse(config.data).params;

  delete data.shoppingCart[params.providerID][params.storeID];

  localStorage.setItem("shopping_cart", JSON.stringify(data.shoppingCart));

  return [200, { shoppingCart: {} }];
});

// ------------------------------------------------
// POST: Add Item in user Cart
// ------------------------------------------------
mock.onPost("/apps/ecommerce/cart").reply(async (config) => {
  // Get product from post data
  const params = JSON.parse(config.data).params;

  const product = params.product;
  const productId = params.product !== null ? params.product.id : 0;
  const type = params.type;
  const storeId = params.store_id;
  const quantity = params.quantity || 0;
  const provider_id = params.provider_id.toString();
  const callService = params.callService;

  if (product != null) {
    let valMax =
      product.max_quantity_by_user_promo === 0
        ? product.max_quantity_by_user
        : product.max_quantity_by_user_promo ?? 0;

    valMax = valMax === 0 ? 10000000000 : valMax;

    const productsLocalStorage = localStorage.getItem("shopping_cart");

    data.shoppingCart =
      productsLocalStorage !== null ? JSON.parse(productsLocalStorage) : {};

    if (data.shoppingCart[provider_id] === undefined) {
      data.shoppingCart[provider_id] = {};
    }

    if (data.shoppingCart[provider_id][storeId] === undefined) {
      data.shoppingCart[provider_id][storeId] = {
        provider_name: product.provider.name,
        products: [],
      };
    }

    const { length } = data.shoppingCart[provider_id][storeId].products;
    let lastId = 0;
    if (length)
      lastId = data.shoppingCart[provider_id][storeId].products[length - 1].id;

    let indexProduct = null;

    if (length > 0) {
      for (const indexArray in data.shoppingCart[provider_id][storeId]
        .products) {
        if (
          data.shoppingCart[provider_id][storeId].products[indexArray]
            .productId === productId
        ) {
          indexProduct = indexArray;
        }
      }
    }

    if (indexProduct !== null) {
      if (quantity === 0) {
        data.shoppingCart[provider_id][storeId].products[indexProduct].qty +=
          type ? 1 : -1;

        if (
          valMax <
          data.shoppingCart[provider_id][storeId].products[indexProduct].qty
        ) {
          data.shoppingCart[provider_id][storeId].products[indexProduct].qty +=
            -1;
        }
      } else {
        data.shoppingCart[provider_id][storeId].products[indexProduct].qty =
          quantity;
      }
    } else {
      if (valMax >= 1) {
        data.shoppingCart[provider_id][storeId].products.push({
          product: {
            id: product.id,
            name: product.name,
            provider_id,
            description: product.description,
            brand: product.brand.name,
            current_price: product.current_price,
            images: product.images,
            scales: product.scales.reverse(),
          },
          id: lastId + 1,
          productId,
          qty: quantity !== 0 ? quantity : 1,
        });
      }
    }
  }

  if (callService) {
    const dataService = { shopping_cart: [] };

    Object.keys(data.shoppingCart).forEach((cart) => {
      const arrayProducts = [];

      data.shoppingCart[cart][storeId].products.forEach((item) => {
        arrayProducts.push({
          price: item.product.current_price,
          id: item.product.id,
          quantity: item.qty,
          recommendation_engine: 0,
          ppd_configuration_groups: [],
        });
      });

      dataService.shopping_cart.push({
        provider_id: Number(cart),
        store_id: Number(storeId),
        payment_method: "CASH",
        wallet_amount_used: 0.0,
        points_used: data.pointsUsed,
        shipping_cost: 0.0,
        quota_qredit: 0.0,
        coupon_used: data.couponID,
        products: arrayProducts,
      });
    });

    console.log("dataService.shopping_cart: ", dataService.shopping_cart)


    const response = await axiosInstanceQuqo.post(
      `${process.env.REACT_APP_API_URL_V2}/v2/mobile/shoppingCart`,
      dataService
    );

    console.log("response ---> shoppingCart: ", response)

    const shoppingCartService = response.data.data.shopping_cart;
    data.alert_msg = response.data.data.alert_msg;

    data.shoppingCart[provider_id][storeId].total =
      shoppingCartService[0].total;
    data.shoppingCart[provider_id][storeId].coupon_amount_discount =
      shoppingCartService[0].coupon_amount_discount;
    data.shoppingCart[provider_id][storeId].points_amount_used =
      shoppingCartService[0].points_amount_used;
    data.shoppingCart[provider_id][storeId].sub_total_order =
      shoppingCartService[0].sub_total_order;
    data.shoppingCart[provider_id][storeId].sub_total =
      shoppingCartService[0].sub_total;
    data.shoppingCart[provider_id][storeId].shipping_cost =
      shoppingCartService[0].shipping_cost;
    data.shoppingCart[provider_id][storeId].min_amount =
      shoppingCartService[0].min_amount;
    data.shoppingCart[provider_id][storeId].discount =
      shoppingCartService[0].discount;
    data.shoppingCart[provider_id][storeId].alert_msg =
      response.data.data.alert_msg;

    for (const index in data.shoppingCart[provider_id][storeId].products) {
      if (shoppingCartService[0].products != null && shoppingCartService[0].products.length > 0) {
        const product = shoppingCartService[0].products.filter(
          (p) =>
            p.id ===
            data.shoppingCart[provider_id][storeId].products[index].productId
        )[0];

        data.shoppingCart[provider_id][storeId].products[index].qty =
          product.quantity;
        data.shoppingCart[provider_id][storeId].products[
          index
        ].product.current_price = product.current_price;
      }
    }

    localStorage.setItem("shopping_cart", JSON.stringify(data.shoppingCart));

    return [200, { shoppingCart: data.shoppingCart }];
  } else {
    let totalCost = 0;
    data.shoppingCart[provider_id][storeId].products.forEach((item) => {
      totalCost += item.product.current_price * item.qty;
    });

    data.shoppingCart[provider_id][storeId].total_cost = totalCost;
    data.shoppingCart[provider_id][storeId].alert_msg = "";

    localStorage.setItem("shopping_cart", JSON.stringify(data.shoppingCart));

    return [200, { shoppingCart: data.shoppingCart }];
  }
});

// ------------------------------------------------
// PUT: Remove Item from user Cart
// ------------------------------------------------
mock.onPut("/apps/ecommerce/cart").reply((config) => {
  // Get product from post data
  const params = JSON.parse(config.data);

  const productId = Number(params.product_id);
  const storeId = params.storeId;

  // Convert Id to number
  const dataKEY = Object.keys(data.shoppingCart)[0];

  const productIndex = data.shoppingCart[dataKEY][storeId].products.findIndex(
    (i) => i.productId === productId
  );
  if (productIndex > -1)
    data.shoppingCart[dataKEY][storeId].products.splice(productIndex, 1);

  if (data.shoppingCart[dataKEY][storeId].products.length === 0) {
    delete data.shoppingCart[dataKEY][storeId];
  }

  localStorage.setItem("shopping_cart", JSON.stringify(data.shoppingCart));

  return [200];
});

// ------------------------------------------------
// POST: Add Item in user Wishlist
// ------------------------------------------------
mock.onPost("/apps/ecommerce/wishlist").reply((config) => {
  // Get product from post data
  const { productId } = JSON.parse(config.data);

  const { length } = data.userWishlist;
  let lastId = 0;
  if (length) lastId = data.userWishlist[length - 1].i;

  data.userWishlist.push({
    id: lastId + 1,
    productId: Number(productId),
  });

  return [201];
});

// ------------------------------------------------
// DELETE: Remove Item from user Wishlist
// ------------------------------------------------
mock.onDelete(/\/apps\/ecommerce\/wishlist\/\d+/).reply((config) => {
  // Get product id from URL
  let productId = config.url.substring(config.url.lastIndexOf("/") + 1);

  // Convert Id to number
  productId = Number(productId);

  const productIndex = data.userWishlist.findIndex(
    (i) => i.productId === productId
  );
  if (productIndex > -1) data.userWishlist.splice(productIndex, 1);

  return [200];
});

// ------------------------------------------------
// POST: Save Coupon ID
// ------------------------------------------------
mock.onPost("/apps/ecommerce/saveCouponID").reply((config) => {
  // Get product from post data
  const params = JSON.parse(config.data);

  data.couponID = params.couponID;

  return [200, { shoppingCart: data.shoppingCart }];
});

// ------------------------------------------------
// POST: Save Coupon ID
// ------------------------------------------------
mock.onPost("/apps/ecommerce/saveTotalQoinsUsed").reply((config) => {
  // Get product from post data
  const params = JSON.parse(config.data);

  data.pointsUsed = params.pointsUsed;

  return [200, { shoppingCart: data.shoppingCart }];
});
