// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// ** Axios Imports
import axios from "axios";
import axiosInstanceQuqo from "../../../../services/handlingPetitions";

export const getProducts = createAsyncThunk(
  "appEcommerce/getProducts",
  async (params) => {
    if (params.categoryID) {
      const response = await axiosInstanceQuqo.get(
        `${process.env.REACT_APP_API_URL_V2}/v2/mobile/products/filter/category/${params.categoryID}?storeID=${params.storeID}&providerID=${params.providerID}`
      );

      const dataRedux = {
        products:
          response.data.data.products !== null
            ? response.data.data.products
            : [],
        total:
          response.data.data.products !== null
            ? response.data.data.products.length
            : 0,
        userCart: [],
        userWishlist: [],
        loadingPage: false,
      };
      return { params, data: dataRedux };
    }
  }
);

export const getProductsRecommended = createAsyncThunk(
  "appEcommerce/getProductsRecommended",
  async (params) => {
    const response = await axiosInstanceQuqo.get(
      `${process.env.REACT_APP_API_URL_V2}/v2/mobile/recommendation/getByUser?storeID=${params.storeID}&providerID=${params.providerID}`
    );
    const dataRedux = {
      products: response.data.data !== null ? response.data.data : [],
      total: response.data.data !== null ? response.data.data.length : 0,
      userCart: [],
      userWishlist: [],
      loadingPage: false,
    };
    return { params, data: dataRedux };
  }
);

export const getSearchProducts = createAsyncThunk(
  "appEcommerce/getSearchProducts",
  async (params) => {
    const response = await axiosInstanceQuqo.get(
      `${process.env.REACT_APP_API_URL_V2}/v2/mobile/products/smart/search?q=${params.q}&providerID=${params.providerID}`
    );
    const dataRedux = {
      products:
        response.data.data.products !== null ? response.data.data.products : [],
      total:
        response.data.data.products !== null
          ? response.data.data.products.length
          : 0,
      userCart: [],
      userWishlist: [],
      loadingPage: false,
    };
    return { data: dataRedux };
  }
);

export const loadingPageProducts = createAsyncThunk(
  "appEcommerce/loadingPageProducts",
  async (params) => {
    const dataRedux = {
      products: [],
      total: 0,
      loadingPage: params.loadingPage,
    };
    return { params, data: dataRedux };
  }
);

export const addToCart = createAsyncThunk(
  "appEcommerce/addToCart",
  async (params) => {
    const response = await axios.post("/apps/ecommerce/cart", { params });
    return response.data;
  }
);

export const successCreateOrder = createAsyncThunk(
  "appEcommerce/successCreateOrder",
  async (params, { dispatch }) => {
    const response = await axios.post("/apps/ecommerce/successCreateOrder", {
      params,
    });
    dispatch(getCartItems());
    return response.data;
  }
);

export const getWishlistItems = createAsyncThunk(
  "appEcommerce/getWishlistItems",
  async () => {
    const response = await axios.get("/apps/ecommerce/wishlist");
    return response.data;
  }
);

export const deleteWishlistItem = createAsyncThunk(
  "appEcommerce/deleteWishlistItem",
  async (id, { dispatch }) => {
    const response = await axios.delete(`/apps/ecommerce/wishlist/${id}`);
    dispatch(getWishlistItems());
    return response.data;
  }
);

export const getCartItems = createAsyncThunk(
  "appEcommerce/getCartItems",
  async () => {
    const response = await axios.get("/apps/ecommerce/cart");
    return response.data;
  }
);

export const getProduct = createAsyncThunk(
  "appEcommerce/getProduct",
  async (slug) => {
    const response = await axios.get(`/apps/ecommerce/products/${slug}`);
    return response.data;
  }
);

export const addToWishlist = createAsyncThunk(
  "appEcommerce/addToWishlist",
  async (id) => {
    await axios.post("/apps/ecommerce/wishlist", { productId: id });
    return id;
  }
);

export const deleteCartItem = createAsyncThunk(
  "appEcommerce/deleteCartItem",
  async (params, { dispatch }) => {
    await axios.put(`/apps/ecommerce/cart`, params);
    dispatch(getCartItems());
    return params.product_id;
  }
);

export const saveCouponID = createAsyncThunk(
  "appEcommerce/saveCouponID",
  async (params, { dispatch }) => {
    await axios.post(`/apps/ecommerce/saveCouponID`, params);
    await dispatch(
      addToCart({
        product: null,
        provider_id: params.providerID,
        store_id: params.useStoreID,
        callService: true,
      })
    );
    return params;
  }
);

export const saveTotalQoinsUsed = createAsyncThunk(
  "appEcommerce/saveTotalQoinsUsed",
  async (params, { dispatch }) => {
    await axios.post(`/apps/ecommerce/saveTotalQoinsUsed`, params);
    await dispatch(
      addToCart({
        product: null,
        provider_id: params.providerID,
        store_id: params.useStoreID,
        callService: true,
      })
    );
    return params;
  }
);

export const appEcommerceSlice = createSlice({
  name: "appEcommerce",
  initialState: {
    cart: [],
    params: {},
    products: [],
    wishlist: [],
    totalProducts: 0,
    productDetail: {},
    shoppingCart: {},
    loadingPage: true,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getProducts.fulfilled, (state, action) => {
        state.params = action.payload.params;
        state.products = action.payload.data.products;
        state.totalProducts = action.payload.data.total;
        state.loadingPage = action.payload.data.loadingPage;
      })
      .addCase(getProductsRecommended.fulfilled, (state, action) => {
        state.params = action.payload.params;
        state.products = action.payload.data.products;
        state.totalProducts = action.payload.data.total;
        state.loadingPage = action.payload.data.loadingPage;
      })
      .addCase(getSearchProducts.fulfilled, (state, action) => {
        state.products = action.payload.data.products;
        state.totalProducts = action.payload.data.total;
        state.loadingPage = action.payload.data.loadingPage;
      })
      .addCase(loadingPageProducts.fulfilled, (state, action) => {
        state.products = action.payload.data.products;
        state.totalProducts = action.payload.data.total;
        state.loadingPage = action.payload.data.loadingPage;
      })
      .addCase(getWishlistItems.fulfilled, (state, action) => {
        state.wishlist = action.payload.products;
        state.loadingPage = action.payload.data.loadingPage;
      })
      .addCase(getCartItems.fulfilled, (state, action) => {
        state.cart = action.payload.products;
        state.shoppingCart = action.payload.shoppingCart;
      })
      .addCase(getProduct.fulfilled, (state, action) => {
        state.productDetail = action.payload.product;
        state.loadingPage = action.payload.data.loadingPage;
      })
      .addCase(addToCart.fulfilled, (state, action) => {
        console.log("state: ", state)
        console.log("action: ", action)
        state.shoppingCart = action.payload.shoppingCart;
      });
  },
});

export default appEcommerceSlice.reducer;
