// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

export const getUserProfile = createAsyncThunk('appChat/getTasks', async () => {
  const response = await axios.get('/apps/chat/users/profile-user')
  return response.data
})

export const getChatContacts = createAsyncThunk('appChat/getChatContacts', async (callService, { dispatch }) => {
  const response = await axios.get('/apps/chat/chats-and-contacts')
  return response.data
})

export const selectChat = createAsyncThunk('appChat/selectChat', async (params, { dispatch }) => {
  const response = await axios.get('/apps/chat/get-chat', { params })
  // await dispatch(getChatContacts())
  return response.data
})

export const changeStatusSidebar = createAsyncThunk('appChat/changeStatusSidebar', async (params, { dispatch }) => {
  const response = await axios.get('/apps/chat/changeStatusSidebar', { params })
  return response.data
})

export const listProductsSideBar = createAsyncThunk('appChat/listProductsSideBar', async (params, { dispatch }) => {
  const response = await axios.get('/apps/chat/listProductsSideBar', { params })
  return response.data
})

export const addProductsRecommended = createAsyncThunk('appChat/addProductsRecommended', async (params, { dispatch }) => {
  const response = await axios.get('/apps/chat/addProductsRecommended', { params })
  return response.data
})

export const addToCartInChat = createAsyncThunk(
  "/apps/chat/addToCartInChat",
  async (params) => {
    const response = await axios.post("/apps/chat/addToCartInChat", { params });
    return response.data;
  }
);

export const removeProductInChat = createAsyncThunk(
  "/apps/chat/addToCartInChat",
  async (params) => {
    const response = await axios.post("/apps/chat/removeProductInChat", { params });
    return response.data;
  }
);

export const sendMsg = createAsyncThunk('appChat/sendMsg', async (obj, { dispatch }) => {
  const response = await axios.post('/apps/chat/send-msg', { obj })
  await dispatch(selectChat(obj.contact.id))
  return response.data
})

export const changeCurrentKeyIframe = createAsyncThunk('appChat/changeCurrentKeyIframe', async () => {
  const response = await axios.post('/apps/chat/changeCurrentKeyIframe')
  return response.data
})

export const appChatSlice = createSlice({
  name: 'appChat',
  initialState: {
    chats: [],
    contacts: [],
    userProfile: {},
    selectedUser: {},
    statusSidebarChat: false,
    listProductsSideBar: [],
    isSidebarRecommended: false,
    isSidebarPromotions: false,
    listProductsRecommended: [],
    currentKeyIframe: 0,
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getUserProfile.fulfilled, (state, action) => {
        state.userProfile = action.payload
      })
      .addCase(getChatContacts.fulfilled, (state, action) => {
        state.chats = action.payload.chatsContacts
        state.contacts = action.payload.contacts
      })
      .addCase(selectChat.fulfilled, (state, action) => {
        state.selectedUser = action.payload
      })
      .addCase(changeStatusSidebar.fulfilled, (state, action) => {
        state.statusSidebarChat = action.payload.statusSidebarChat
        state.isSidebarRecommended = action.payload.isSidebarRecommended
        state.isSidebarPromotions = action.payload.isSidebarPromotions
        state.listProductsRecommended = [];
      })
      .addCase(listProductsSideBar.fulfilled, (state, action) => {
        state.listProductsSideBar = action.payload.listProductsSideBar
      })
      .addCase(addProductsRecommended.fulfilled, (state, action) => {
        state.listProductsRecommended = action.payload.listProductsRecommended
      })
      .addCase(addToCartInChat.fulfilled, (state, action) => {
        state.contacts = action.payload.contacts
        // state.selectedUser = action.payload
      })
      .addCase(changeCurrentKeyIframe.fulfilled, (state, action) => {
        state.currentKeyIframe = action.payload.data
        // state.selectedUser = action.payload
      })
  }
})

export default appChatSlice.reducer
