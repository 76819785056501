// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import axiosInstanceQuqo from '../../../../services/handlingPetitions'


export const getData = createAsyncThunk('appUsers/getData', async params => {
  const response = await axiosInstanceQuqo.get(`${process.env.REACT_APP_API_URL_V2}/v2/admin/user/getAll/${params.provider_id}?limit=10&Offset=${10 * (params.page - 1)}&field=${params.useFilterField}&text=${params.useFilterText}`)
  return {
    params,
    data: response.data.data.users,
    totalPages: response.data.data.total,
    loadingTable:false
  }
})

export const cleanDataUsers = createAsyncThunk('cleanDataUsers/clean', async () => {
  return {
    data: [],
    loadingTable:true
  }
})

export const getUser = createAsyncThunk('appUsers/getUser', async id => {
  const response = await axios.get('/api/users/user', { id })
  return response.data.user
})


export const appUsersSlice = createSlice({
  name: 'appUsers',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedUser: null,
    loadingTable: true
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.loadingTable = action.payload.loadingTable
        state.total = action.payload.totalPages
      })
      .addCase(cleanDataUsers.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.loadingTable = action.payload.loadingTable
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.selectedUser = action.payload
      })
  }
})

export default appUsersSlice.reducer
